import React from 'react';
import dynamic from "next/dynamic";
import _ from 'lodash';
import Typography from "@material-ui/core/Typography";
import {parseGutenbergReact} from "../../../api/global";
import NoSidebarLayout from "../PageLayouts/NoSidebarLayout";

const RentalPassPhrase = dynamic(() => import('../../01_atoms/form/RentalAppPassForm'), {ssr: false});

let layoutOpts = {
  titleBgWide: false,
  titleBgClr: "warning.dark",
  mainBgClr: "",
  // mainBgImg: '/images/bg-c-n-page.jpg',
  sideBgClr: false,
  topBgClr: false,
};

const DefaultNode = (props) => {
  let {node, opts} = props;
  layoutOpts = opts ? {...layoutOpts, opts} : layoutOpts;
  if (node?.bgSlider?.length > 0) {
    layoutOpts.topBgClr = node.bgSlider;
  }

  let {
    id,
    title,
    hideTitle,
    summary,
    body,
    body_json,
  } = node;

  let renderedBodyVar = null;
  if (node?.internalId == 15534) {
    if (body_json?.json?.length > 0) {
      renderedBodyVar = body_json.json.map((block, key) => {
        return parseGutenbergReact(key, block);
      });
    }
    if (body?.length > 0 && _.isEmpty(body_json)) {
      renderedBodyVar = (
          <Typography paragraph component={`div`}>
            <div dangerouslySetInnerHTML={{__html: body}}></div>
          </Typography>
      );
    }
  }
  else {
    if (body_json?.json?.length > 0) {
      renderedBodyVar = body_json.json.map((block, key) => {
        return parseGutenbergReact(key, block);
      });
    }
    if (body?.length > 0 && _.isEmpty(body_json)) {
      renderedBodyVar = (
          <Typography paragraph component={`div`}>
            <div
                dangerouslySetInnerHTML={{__html: `<div class="inner-html">${body}</div>`}}></div>
          </Typography>
      );
    }
  }

  return (
      <NoSidebarLayout title={title} hideTitle={hideTitle} summary={summary}
                       opts={layoutOpts}>

        {node?.internalId == 15534 &&
        <RentalPassPhrase>
          {renderedBodyVar}
        </RentalPassPhrase>
        }
        {node?.internalId != 15534 && renderedBodyVar}

      </NoSidebarLayout>
  );
};

export default DefaultNode;
